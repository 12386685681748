import { Controller } from 'stimulus';
import throttle from 'lodash/throttle';
import Swiper from 'swiper';

export default class extends Controller {
  static targets = ['container', 'slide'];

  initialize() {
    window.addEventListener('load', this.initSlider.bind(this));
    if (this.isTouchDevice()) return;
    // do not add resize event listener on touch devices
    window.addEventListener('resize', throttle(this.resize.bind(this), 250, { leading: false }));
  }

  isTouchDevice() {
    return (('ontouchstart' in window) ||
       (navigator.maxTouchPoints > 0) ||
       (navigator.msMaxTouchPoints > 0));
  }

  resize() {
    this.disconnect();
    this.removeHover();
    document.body.classList.add('no-transition');
    this.initSlider();
  }

  disconnect() {
    if (this.swiper) this.swiper.destroy(true, true);
  }

  initSlider() {
    this.swiper = new Swiper(this.containerTarget, {
      slidesPerView: "auto",
      centeredSlides: true,
      speed: 800,
      autoHeight: false,
      loop: true,
      updateOnWindowResize: false,
      resizeObserver: false,
      breakpoints: {
        1024: {
          centeredSlides: false,
        }
      },
      on: {
        afterInit: () => {
          setTimeout(() => {
            this.containerTarget.style.opacity = 1;
            document.body.classList.remove('no-transition');
          }, 1000);
          this.slideWidth = this.getWidth();
        },
        beforeTransitionStart: () => {
          let active = document.querySelector('.swiper-slide-active');
          this.setActive(active);
        },
      }
    });
    this.setControls();
    this.setActive(document.querySelector('.swiper-slide-active'));
    this.slideTargets.forEach((slide) => {
      slide.addEventListener('mouseenter', (e) => this.setActive(e.currentTarget));
    });
    this.containerTarget.addEventListener('mouseleave', () => this.resetActive());
  }

  setControls() {
    let controls = document.querySelector('.carousel__controls');
    if (window.innerWidth > 1023) {
      controls.removeAttribute('style');
      return;
    }
    let text = document.querySelector('.carousel__text');
    let image = document.querySelector('.carousel__image');
    controls.style.top = `calc(${image.height / 2}px + ${text.clientHeight}px)`;
  }

  removeHover() {
    document.querySelectorAll('.hover').forEach((el) => {
      el.classList.remove('hover');
    });
  }

  getWidth() {
    const element = document.querySelector('.carousel__slide');
    const style = getComputedStyle(element);
    const width = element.offsetWidth;
    const marginLeft = parseInt(style.marginLeft, 10);
    const marginRight = parseInt(style.marginRight, 10);
    return width + marginLeft + marginRight;
  }

  fixLoop() {
    // Due to the way swiper loop works, we need to manually fix the loop by
    // removing the css transitions when the carousel container jumps more than
    // the width of the slide move. See issue comment where bug was identified:
    // https://github.com/kingandpartners/grandlife-hotels/issues/3518#issuecomment-2621575188
    if (!this.swiper) return;
    if (!this.translate) {
      this.distance = 0;
    } else {
      this.distance = Math.floor(Math.abs(Math.abs(this.swiper.translate) - Math.abs(this.translate)));
    }
    this.translate = this.swiper.translate;
    if (this.distance > this.slideWidth) {
      document.body.classList.add('no-transition');
    } else {
      document.body.classList.remove('no-transition');
    }
  }

  setActive(el) {
    if (window.innerWidth < 1024) return;
    this.fixLoop();
    this.removeHover();
    el.classList.add('hover');
  }

  resetActive() {
    this.setActive(document.querySelector('.swiper-slide-active'));
  }

  prev() {
    this.swiper.slidePrev();
  }

  next() {
    this.swiper.slideNext();
  }
}

